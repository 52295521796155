import styled from 'styled-components';
import { simplePostType } from 'types/postType';
import HeaderWithImg from './headerWithImg';
import DetailMenu from './detailMenu';
import selectArrow from '../assets/selectArrow.svg';
import {
  FiChevronLeft,
  FiChevronsLeft,
  FiChevronRight,
  FiChevronsRight,
} from 'react-icons/fi';
import Footer from './footer';
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
const TableOfPostsCss = styled.div`
  .flexContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .listHeader {
    display: flex;
    margin: 0 10%;
    border-bottom: 1px solid #1b1d1f;
    padding: 10px 0;
    @media (min-width: 1280px) and (max-width: 1920px) {
      margin: 0px calc(50vw - 640px);
    }
    @media (max-width: 784px) {
      flex-direction: column-reverse;
      margin: 0 5%;
    }
    .left,
    .right {
      display: flex;
      align-items: center;
      width: 50%;
      @media (max-width: 784px) {
        width: 100%;
      }
    }
    .left {
      display: flex;
      @media (max-width: 784px) {
        font-size: 12px;
        margin-top: 20px;
      }
      span {
        font-weight: 700;
      }

      .curPage {
        margin-left: 20px;
        color: #1b1d1f;
      }
    }
    .right {
      justify-content: end;
      select,
      .searchbar,
      .search {
        height: 40px;
        box-sizing: border-box;
        @media (max-width: 784px) {
          font-size: 14px;
        }
      }
      select {
        appearance: none;
        background: url(${selectArrow}) no-repeat right 15px center;
        border: 1px solid #f5f5f5;
        padding: 0px 60px 0px 16px;
        width: max-content;
        @media (max-width: 784px) {
          padding: 0px 40px 0px 16px;
          width: 30%;
          font-size: 14px;
        }
      }
      .searchbar {
        margin-left: 30px;
        padding: 2px 16px;
        border: 1px solid #f5f5f5;
        width: 30%;
        @media (max-width: 784px) {
          margin-left: 10px;
          padding: 2px 12px;
          width: 55%;
        }
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: #9ea0a3;
        }
      }
      .search {
        white-space: nowrap;
        margin: 0;
        color: #ffffff;
        background: #093c62;
        width: max-content;
        width: 15%;
        padding: 16px;
        cursor: pointer;
      }
    }
  }
  .listContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 10%;
    gap: calc(8% / 3);
    min-height: 300 px;
    @media (min-width: 1280px) and (max-width: 1920px) {
      padding: 40px calc(50vw - 640px);
    }
    @media (max-width: 784px) {
      padding: 20px 5%;
    }
  }

  .paging {
    display: flex;
    align-items: center;
    justify-content: center;
    .icon,
    .anchor {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon {
      font-size: 24px;
      margin: 0 10px;
      cursor: pointer;
    }
    .anchor {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      cursor: pointer;
    }
    .circle {
      background: #093c62;
      color: #ffffff;
    }
  }
  .toList {
    width: 85px;
    height: 50px;
    text-decoration: none;
    border: 1px solid #9ea0a3;
    color: #1b1d1f;
    margin: 20px 0;
  }

  .table {
    width: 80%;
    margin: 0 10%;
    border-collapse: collapse;
    @media (min-width: 1280px) and (max-width: 1920px) {
      width: 1280px;
      margin: 0 calc(50vw - 640px);
    }
    @media (max-width: 784px) {
      margin: 0 5%;
      width: 90%;
    }
    tr {
      border-bottom: 1px solid #c6c8cb;
    }
    th,
    .td {
      height: 40px;
      width: calc(100% / 14);
      font-weight: 400;
      text-align: center;
      @media (max-width: 784px) {
        display: none;
      }
    }
    th:nth-child(2),
    .td:nth-child(2) {
      width: calc(500% / 7);
    }
    .head {
      border-bottom: 1px solid #4e5053;
      @media (max-width: 784px) {
        display: none;
      }
    }
  }
  .col {
    cursor: pointer;
  }
  .mobileaTd {
    display: none;
    p:nth-child(1) {
      font-weight: 700;
    }
    p:nth-child(2) {
      font-size: 12px;
      color: #76787b;
    }
    @media (max-width: 784px) {
      display: block;
    }
  }

  .empty {
    width: 100%;
    height: 300px;
    border-bottom: 1px solid #c6c8cb;
  }
  .fileImg {
    width: 20px;
  }
`;
type props = {
  header: { src: string; menu: string; detail: string };
  detail: { name: string };
  postList: simplePostType[];
  inquiry?: boolean;
  total: number;
  pageNum: number;
  type: string;
  loadData: (type: string, page: number, searchWord: string) => void;
};
function TableOfPosts({
  header,
  detail,
  postList,
  inquiry = false,
  total,
  pageNum,
  loadData,
}: props) {
  function Anchor() {
    const tmp: JSX.Element[] = [];
    const start = 1 + Math.floor((page - 1) / 5) * 5;
    let max = start + 4 >= pageNum ? pageNum : start + 4;
    if (max === 0) max += 1;
    for (let i: number = start; i <= max; i++) {
      tmp.push(
        page === i ? (
          <p
            className="anchor circle"
            key={i}
            onClick={() => {
              setPage(i);
            }}
          >
            {i}
          </p>
        ) : (
          <p
            className="anchor"
            key={i}
            onClick={() => {
              setPage(i);
            }}
          >
            {i}
          </p>
        ),
      );
    }
    return tmp;
  }
  const [page, setPage] = useState<number>(1);
  const [searchWord, setSearchWord] = useState<string>('');
  const pageString = page + '/' + (pageNum > 0 ? pageNum : 1);
  const searchType = useRef<string>('none');
  useEffect(() => {
    loadData(searchType.current, page - 1, searchWord);
  }, [page]);
  return (
    <TableOfPostsCss>
      <HeaderWithImg
        img={header.src}
        menu={header.menu}
        detail={header.detail}
      />
      <DetailMenu name={detail.name} />
      <div>
        <div className="listHeader">
          <div className="left">
            <p className="total">
              전체&nbsp;&nbsp;
              <span className="bold">{total}건</span>
            </p>
            <p className="curPage">현재 페이지&nbsp;&nbsp;{pageString}</p>
          </div>
          <div className="right">
            <select
              onChange={(e) => {
                searchType.current = e.target.value;
              }}
            >
              {!inquiry && (
                <option value={'all'} defaultChecked={true}>
                  전체
                </option>
              )}
              <option value={'title'} defaultChecked={inquiry}>
                제목
              </option>
              {!inquiry && <option value={'content'}>내용</option>}
            </select>
            <input
              type="text"
              className="searchbar"
              placeholder="검색어를 입력하세요"
              onChange={(e) => {
                setSearchWord(e.target.value);
              }}
            />
            <p
              className="search flexContainer"
              onClick={() => {
                if (searchType.current === 'none') searchType.current = 'all';
                loadData(searchType.current, page - 1, searchWord);
              }}
            >
              검색
            </p>
          </div>
        </div>
        <table className="table">
          <tr className="head">
            <th>번호</th>
            <th>제목</th>
            <th>등록일</th>
            <th>첨부파일</th>
            <th>{inquiry ? '처리상태' : '조회수'}</th>
          </tr>
          {postList.map((e, idx) => {
            return (
              <tr
                className="col"
                key={idx}
                onClick={() => {
                  if (!inquiry) window.location.href = `/notice/detail/${e.id}`;
                }}
              >
                <td className="td">{e.id}</td>
                <td className="td">{e.title}</td>
                <td className="td">{e.create_at}</td>
                <td className="td">
                  {e.src.length > 0 && (
                    <img src={e.src} alt="file" className="fileImg" />
                  )}
                </td>
                {inquiry ? (
                  <td className="td">
                    {e.is_completed ? '답변완료' : '처리중'}
                  </td>
                ) : (
                  <td className="td">{e.view}</td>
                )}
                <td className="mobileaTd">
                  <div>
                    <p> {e.title}</p>
                    <p> {e.create_at}</p>
                  </div>
                </td>
              </tr>
            );
          })}
          {postList.length === 0 && <tr className="empty" />}
        </table>
        <div className="paging">
          <FiChevronsLeft
            className="icon"
            onClick={() => {
              setPage(page >= 6 ? page - 5 : 1);
            }}
          />
          <FiChevronLeft
            className="icon"
            onClick={() => {
              setPage(page > 1 ? page - 1 : 1);
            }}
          />
          {Anchor()}
          <FiChevronRight
            className="icon"
            onClick={() => {
              setPage(page < pageNum ? page + 1 : pageNum);
            }}
          />
          <FiChevronsRight
            className="icon"
            onClick={() => {
              setPage(page + 5 <= pageNum ? page + 5 : pageNum);
            }}
          />
        </div>
      </div>
      {inquiry && (
        <div className="flexContainer">
          <Link to="/inquiry/form" className="toList flexContainer">
            글쓰기
          </Link>
        </div>
      )}
      <Footer />
    </TableOfPostsCss>
  );
}
export default TableOfPosts;
