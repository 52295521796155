import styled from 'styled-components';
const ServiceDescriptionCss = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  box-sizing: border-box;
  width: 46%;
  border: 2px solid #eef0f3;
  margin: 25px 0;
  @media (max-width: 784px) {
    width: 90%;
    margin-top: 20px;
    padding: 15px 10px;
  }
  .text {
    padding: 0 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    @media (max-width: 784px) {
      font-size: 12px;
    }
  }
  @media (max-width: 784px) {
    img {
      width: 50px;
      height: 50px;
    }
  }
`;
type props = {
  img: string;
  text: string;
};
function ServiceDescription({ img, text }: props) {
  return (
    <ServiceDescriptionCss>
      <img src={img} alt="Img" />
      <p className="text">{text}</p>
    </ServiceDescriptionCss>
  );
}

export default ServiceDescription;
