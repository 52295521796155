import styled from 'styled-components';
const HeaderAreaCss = styled.div`
  width: 100vw;
  height: 100px;
  @media (max-width: 784px) {
    height: 60px;
  }
`;
function HeaderArea() {
  return <HeaderAreaCss />;
}

export default HeaderArea;
