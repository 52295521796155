import styled from 'styled-components';
import HeaderWithImg from 'components/headerWithImg';
import introHeaderImg from '../assets/headerImg/intro.svg';
import introImg from '../assets/intro/introImg.svg';
import mobileIntroImg from '../assets/intro/mobileIntroImg.svg';
import Footer from 'components/footer';
const IntroCss = styled.div`
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 10%;
    @media (min-width: 1280px) and (max-width: 1920px) {
      padding: 100px calc(50vw - 640px);
    }
    @media (max-width: 784px) {
      flex-direction: column;
      align-items: start;
      padding: 50px 5%;
    }
    .textArea {
      height: 100%;
      max-width: 550px;
      .title {
        font-size: 48px;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 50px;
        @media (max-width: 784px) {
          font-size: 24px;
          margin-top: 50px;
          margin-bottom: 20px;
        }
      }
      .detailContent {
        font-size: 18px;
        font-weight: 400;
        margin-top: 2rem;
        line-height: 30px;
        @media (max-width: 784px) {
          font-size: 16px;
        }
      }
    }
  }
  .mobileIntroImg {
    display: none;
    @media (max-width: 784px) {
      display: block;
    }
  }

  .introImg {
    max-width: 500px;
    max-height: 700px;
    margin-left: 80px;
    @media (max-width: 784px) {
      display: none;
    }
  }

  .signatureArea {
    display: flex;
    margin-top: 50px;
    @media (max-width: 784px) {
      margin-top: 30px;
      justify-content: center;
      align-items: center;
    }
    .signature {
      font-size: 20px;
      font-weight: 600;
      @media (max-width: 784px) {
        font-size: 18px;
      }
    }
  }
`;
function Intro() {
  const detailContent: string[] = [
    `안녕하세요, "동행하는 사람들"에 오신 것을 환영합니다. 저희 회사는 장애인들의 더 나은 삶과 편리한 일상을 위해 긴급출동 서비스, 장애인식 개선 강의 및 전시회, 
    심리상담 등을 제공하는 기업입니다.`,
    `저희는 장애인들이 긴급 상황에서 신속하게 도움을 받을 수 있도록 24시간 긴급출동 서비스를 운영하고 있습니다. 
    또한, 장애인식 개선을 위한 강의와 전시회를 통해 사회적 인식을 높이고, 심리상담 서비스를 통해 장애인들이 정신적 어려움을 극복할 수 있도록 지원합니다.`,
    `저희 "동행하는 사람들"은 장애인들의 든든한 동반자가 되어, 그들의 삶을 더욱 풍요롭게 만들기 위해 최선을 다하고 있습니다. 
    앞으로도 장애인들의 행복을 위해 지속적으로 노력하겠습니다. 감사합니다.`,
  ];
  return (
    <IntroCss>
      <HeaderWithImg img={introHeaderImg} detail="기업소개" />

      <div className="content">
        <img src={mobileIntroImg} className="mobileIntroImg" alt="introduce" />
        <div className="textArea">
          <p className="title">World Without Barrier</p>
          <p className="detailContent">{detailContent[0]}</p>
          <p className="detailContent">{detailContent[1]}</p>
          <p className="detailContent">{detailContent[2]}</p>
          <div className="signatureArea">
            <p className="signature">(주) 동행하는 사람들 박하은 드림</p>
          </div>
        </div>
        <img src={introImg} className="introImg" alt="introduce" />
      </div>
      <Footer />
    </IntroCss>
  );
}

export default Intro;
