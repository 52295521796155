import Bussiness from '../components/bussiness';
import eduHeaderImg from '../assets/headerImg/education.svg';
import desc1 from '../assets/bussiness/education_desc_1.svg';
import desc2 from '../assets/bussiness/education_desc_2.svg';
function Education() {
  const header = { src: eduHeaderImg, detail: '체험/교육' };
  const detail = {
    name: '장애인식개선 체험 키트 및 교육',
    description: `장애물 감지 시 진동으로 알려주는 시각장애인식개선 체험 키트, 
    점자와 한글을 함께 배우고 학습할 수 있는 한점 키트와 학습지 등을 활용하여 
    단순 강의가 아닌 실제 장애를 체험함으로 좀 더 장애를 직접적으로 이해할 수 있도록 체험과 교육을 지원합니다.`,
  };
  const imgList: { src: string; alt: string }[] = [];
  const service = [
    {
      src: desc1,
      text: '눈을 가리고 걸으면서 장애물을 감지해보는 등 체험형 키트',
    },
    {
      src: desc2,
      text: '키트를 체험한 후 느낀 점을 바탕으로 장애인식개선 교육 및 강의',
    },
  ];
  return (
    <Bussiness
      header={header}
      detail={detail}
      imgList={imgList}
      service={service}
    />
  );
}

export default Education;
