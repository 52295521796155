import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { postType } from 'types/postType';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa6';
import HeaderWithImg from './headerWithImg';
import DetailMenu from './detailMenu';
import Footer from './footer';
import hwp from '../assets/hwp.svg';
import pdf from '../assets/pdf.svg';
import fileImg from '../assets/file.svg';
import { GoDownload } from 'react-icons/go';
import axios from 'axios';
import { detailPageDateFormat } from 'utils/dateFormat';
const PostDetailCss = styled.div`
  color: #1b1d1f;
  .container {
    padding: 0 10%;
    @media (min-width: 1280px) and (max-width: 1920px) {
      padding: 0px calc(50vw - 640px);
    }
    @media (max-width: 784px) {
      padding: 0 5%;
    }
  }
  .title {
    font-size: 28px;
    padding: 10px 0;
    margin: 0;
    border-bottom: 1px solid #1b1d1f;
    letter-spacing: -1.4px;
    text-align: center;
    @media (max-width: 784px) {
      font-size: 20px;
      text-align: left;
    }
  }
  .subHeader {
    display: flex;
    justify-content: right;
    @media (max-width: 784px) {
      justify-content: left;
    }
    .view {
      font-size: 18px;
      font-weight: 700;
      margin: 20px 0 100px 0;
      @media (max-width: 784px) {
        font-size: 12px;
        text-align: left;
        margin-bottom: 40px;
      }
      span {
        color: #76787b;
        font-weight: 400;
      }
      &:nth-child(1) {
        margin-right: 20px;
      }
    }
  }
  .content {
    margin: 80px 0 100px 0;
    @media (max-width: 784px) {
      font-size: 14px;
      margin: 20px 0 40px 0;
    }
  }
  .flexContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .imgArea {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      min-width: 40%;
      max-width: 90%;
      background: gray;
      margin-bottom: 30px;
      object-fit: contian;
    }
  }
  .toList {
    width: 85px;
    height: 50px;
    text-decoration: none;
    border: 1px solid #9ea0a3;
    color: #1b1d1f;
    margin: 20px 0;
  }
  .otherPost {
    display: flex;
    align-items: center;
    border-top: 1px solid #c6c8cb;
    text-decoration: none;
    color: #1b1d1f;
    @media (max-width: 784px) {
      font-size: 14px;
    }
    .text:nth-child(2) {
      margin-left: 10px;
    }
    .text:nth-child(3) {
      margin-left: 20px;
    }
  }
  .next {
    border-bottom: 1px solid #c6c8cb;
    margin-bottom: 200px;
  }

  .fileArea {
    display: flex;
    border-top: 1px solid #c6c8cb;
    border-bottom: 1px solid #c6c8cb;
    @media (max-width: 784px) {
      display: block;
    }
    .fileHead {
      width: 10%;
      font-weight: 700;
      font-size: 18px;
      margin-top: 10px;
      @media (max-width: 784px) {
        width: 100%;
      }
    }

    .fileList {
      width: 90%;
      @media (max-width: 784px) {
        width: 100%;
      }
    }
    .file {
      display: flex;
      align-items: center;
      padding: 10px;

      @media (max-width: 784px) {
        font-size: 12px;
      }
      .fileName {
        margin-left: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 70%;
        @media (max-width: 784px) {
          font-size: 12px;
          width: 50%;
        }
      }
      .img {
        width: 30px;
        height: 30px;
        @media (max-width: 784px) {
          width: 20px;
          height: 20px;
        }
      }
      .downloadArea {
        display: flex;
        flex-grow: 1;
        justify-content: end;
        cursor: pointer;
        .download {
          border: 1px solid #9ea0a3;
          padding: 5px;
          font-size: 12px;
          margin: 0;
        }
        .icon {
          margin-left: 10px;
        }
      }
    }
  }
`;
type props = {
  header: { src: string; menu: string; detail: string };
  detail: { name: string };
  post: postType;
  listPageLink: string;
  file?: boolean;
};
function PostDetail({
  header,
  detail,
  post,
  listPageLink,
  file = false,
}: props) {
  const viewString = post.view.toLocaleString();
  const handleFileDownload = (src: string) => {
    axios
      .get(src, { responseType: 'blob' })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = src;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error('파일 다운로드 오류:', error);
      });
  };
  return (
    <PostDetailCss>
      <HeaderWithImg
        img={header.src}
        menu={header.menu}
        detail={header.detail}
      />
      <DetailMenu name={detail.name} />
      <div className="container">
        <h1 className="title">{post.title}</h1>
        <div className="subHeader">
          <p className="view">
            작성일시&nbsp; <span>{detailPageDateFormat(post.create_at)}</span>
          </p>
          <p className="view">
            조회수&nbsp; <span>{viewString}</span>
          </p>
        </div>
        {!file && (
          <div className="imgArea">
            {post.src.map((e, idx) => {
              return <img src={e} key={idx} className="img" />;
            })}
          </div>
        )}
        <p className="content">
          {post.content.length > 0 &&
            post.content.split('\n').map((e) => {
              return (
                <>
                  {e} <br />
                </>
              );
            })}
        </p>
        {file && post.src.length > 0 && (
          <div className="fileArea">
            <p className="fileHead">첨부파일</p>
            <div className="fileList">
              {post.src.map((e, idx) => {
                return (
                  <div className="file" key={idx}>
                    {e.includes('hwp') ? (
                      <img src={hwp} className="img" />
                    ) : e.includes('pdf') ? (
                      <img src={pdf} className="img" />
                    ) : (
                      <img src={fileImg} className="img" />
                    )}
                    <div className="img" />
                    <span className="fileName"> {e}</span>
                    <div
                      className="downloadArea"
                      onClick={() => {
                        handleFileDownload(e);
                      }}
                    >
                      <p className="download">
                        다운로드
                        <GoDownload className="icon" />
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="flexContainer">
          <Link to={listPageLink} className="toList flexContainer">
            목록
          </Link>
        </div>
        {post.prev !== null && (
          <Link
            to={`${listPageLink}/detail/${post.prev}`}
            className="otherPost"
            style={{
              borderBottom: post.next === null ? '1px solid #c6c8cb' : '0',
            }}
          >
            <FaAngleUp />
            <p className="text">이전글</p>
            <p className="text">공지사항 1</p>
          </Link>
        )}
        {post.next !== null && (
          <Link
            className="otherPost next"
            to={`${listPageLink}/detail/${post.next}`}
          >
            <FaAngleDown />
            <p className="text">다음글</p>
            <p className="text">공지사항 1</p>
          </Link>
        )}
      </div>
      <Footer />
    </PostDetailCss>
  );
}
export default PostDetail;
