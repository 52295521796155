import styled from 'styled-components';
const DetailMenuCss = styled.div`
  width: 100vw;
  padding: 50px 10%;
  box-sizing: border-box;
  @media (min-width: 1280px) and (max-width: 1920px) {
    padding: 50px calc(50vw - 640px);
  }
  .name {
    font-size: 32px;
    color: #060606;
    font-weight: 700;
    padding: 0 16px;
    border-left: 5px solid #093c62;
    margin: 0;
    margin-bottom: 26px;
  }
  .description {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  @media (max-width: 784px) {
    padding: 50px 5%;
    .name {
      font-size: 20px;
    }
    .description {
      font-size: 14px;
    }
  }
`;

type props = {
  name: string;
  description?: string;
};
function DetailMenu({ name, description = '' }: props) {
  return (
    <DetailMenuCss>
      <p className="name">{name}</p>
      <p className="description">{description}</p>
    </DetailMenuCss>
  );
}

export default DetailMenu;
