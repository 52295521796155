import { useState } from 'react';
import ListOfPost from 'components/listOfPosts';
import headerImg from '../assets/headerImg/article.svg';
import { simplePostType } from 'types/postType';
import axios from 'axios';
function ArticleList() {
  const header = { src: headerImg, menu: '알림마당', detail: '언론보도' };
  const detail = { name: '언론보도' };
  const [postList, setPostList] = useState<simplePostType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [pageNum, setPageNum] = useState<number>(0);
  function loadData(type: string, page: number, searchWord: string) {
    if (type === 'none') {
      getData(`/article/all?`, page).catch((e) => {
        console.log(e);
      });
    } else if (type === 'all') {
      getData(
        `/article/search/all?title=${searchWord}&content=${searchWord}&`,
        page,
      ).catch((e) => {
        console.log(e);
      });
    } else if (type === 'title') {
      getData(`/article/search/title?title=${searchWord}&`, page).catch((e) => {
        console.log(e);
      });
    } else if (type === 'content') {
      getData(`/article/search/content?content=${searchWord}&`, page).catch(
        (e) => {
          console.log(e);
        },
      );
    }
    return;
  }
  async function getData(url: string, page: number) {
    const res = await axios.get(
      `${process.env.REACT_APP_ORIGIN}${url}page=${page}&size=8`,
    );
    setTotal(res.data.data.total);
    setPageNum(res.data.data.page);
    const simplePostList: simplePostType[] = [];
    res.data.data.result.forEach((e: any) => {
      console.log(e);
      e.create_at = e.create_at.split('T')[0].replaceAll('-', '/');
      simplePostList.push(e);
    });
    setPostList(simplePostList);
  }

  return (
    <ListOfPost
      header={header}
      detail={detail}
      postList={postList}
      total={total}
      pageNum={pageNum}
      type={'article'}
      loadData={loadData}
    />
  );
}

export default ArticleList;
