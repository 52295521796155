import HeaderWithImg from 'components/headerWithImg';
import styled from 'styled-components';
import headerImg from '../assets/headerImg/inquiry.svg';
import DetailMenu from 'components/detailMenu';
import Footer from 'components/footer';
import { Link } from 'react-router-dom';
import { optionalInquiryType } from 'types/inquiryType copy';
import { useRef, useState } from 'react';
import axios from 'axios';
import { HiOutlineXMark } from 'react-icons/hi2';
const InquiryFormCss = styled.div`
  .flexContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .toList {
    width: 85px;
    height: 50px;
    text-decoration: none;
    border: 1px solid #9ea0a3;
    color: #1b1d1f;
    margin: 20px 10px;
    cursor: pointer;
  }

  table {
    margin: 0 10%;
    width: 80%;
    border-collapse: collapse;
    @media (min-width: 1280px) and (max-width: 1920px) {
      width: 1280px;
      margin: 0 calc(50vw - 640px);
    }
    @media (max-width: 784px) {
      margin: 0 5%;
      width: 90%;
    }
    .name {
      padding-top: 20px;
      vertical-align: top;
    }
    td:nth-child(1) {
      font-weight: 700;
      width: 20%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td {
      border-bottom: 1px solid #9ea0a3;
      padding: 10px 0;
      height: 40px;
      .title,
      .content {
        width: 85%;
        @media (max-width: 784px) {
          width: 70%;
        }
      }
      .content {
        height: 400px;
      }
      @media (max-width: 784px) {
        .email {
          width: 22%;
        }
      }
    }
    .last {
      border: 0;
    }
    .fileArea {
      padding: 10px 20px;
      @media (max-width: 784px) {
        margin-left: 10px;
      }
    }
  }

  input[type='text'],
  textarea {
    border: 1px solid #c6c8cb;
    height: 20px;
    padding: 10px 20px;
    @media (max-width: 784px) {
      margin-left: 10px;
    }
  }
  input[type='text']:focus,
  textarea {
    outline: none;
    border: 1px solid #1c7393;
  }

  .input {
    width: 40%;
  }
  #file {
    display: none;
  }
  .fileLabel {
    width: 80px;
    display: flex;
    justify-content: center;
    padding: 5px;
    border: 1px solid #9ea0a3;
    background: #dfdfe0;
    border-radius: 3px;
    @media (max-width: 784px) {
      font-size: 12px;
    }
  }
  .fileLimit {
    font-size: 16px;
    font-weight: 700;
    @media (max-width: 784px) {
      font-size: 12px;
    }
  }

  .fileInfo {
    display: flex;
    align-items: center;
    .icon {
      font-size: 18px;
      width: 10%;
    }
  }
  .fileName {
    width: 90%;
    color: #007cff;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 784px) {
      font-size: 12px;
    }
  }
`;
function InquiryForm() {
  const inquiry = useRef<optionalInquiryType>({
    title: '',
    writer: '',
    phone: '',
    email: '',
    content: '',
  });
  const emailInput1 = useRef<string>('');
  const emailInput2 = useRef<string>('');
  const [imgPath, setImgPath] = useState<string[]>([]);
  const multipart = useRef<Blob[]>([]);
  const fileSize = useRef<number>(0);
  const fileSizeList = useRef<number[]>([]);
  async function postInquiry() {
    const res = await axios.post(`${process.env.REACT_APP_ORIGIN}/inquiry`, {
      data: { ...inquiry.current },
    });
    const id = res.data.data.id;
    if (imgPath.length > 0) {
      const formData = new FormData();
      for (const e of multipart.current) {
        formData.append('multipartFile', e);
      }
      const res1 = await axios.post(
        `${process.env.REACT_APP_ORIGIN}/img-file/upload?dir=inquiry/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      res1.data.data.forEach((e: string) => {
        axios
          .post(`${process.env.REACT_APP_ORIGIN}/img/inquiry`, {
            data: {
              src: `${process.env.REACT_APP_S3}${e}`,
              target: id,
            },
          })
          .catch(() => {
            alert('이미지를 등록할 수 없습니다');
          });
      });
    }
    alert('등록되었습니다');
    window.location.href = `/inquiry`;
    return;
  }
  function create() {
    if (
      inquiry.current.title !== undefined &&
      inquiry.current.title.length < 2
    ) {
      alert('제목은 2자 이상 입력해야 합니다');
    } else if (
      inquiry.current.writer !== undefined &&
      inquiry.current.writer.length < 2
    ) {
      alert('작성자는 2자 이상 입력해야 합니다');
    } else if (
      inquiry.current.email !== undefined &&
      !/^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
        inquiry.current.email,
      )
    ) {
      alert('이메일 주소가 아닙니다');
    } else if (
      inquiry.current.phone !== undefined &&
      !/^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(
        inquiry.current.phone,
      )
    ) {
      alert('핸드폰 번호를 확인해주세요');
    } else if (
      inquiry.current.content !== undefined &&
      inquiry.current.content.length < 2
    ) {
      alert('문의내용은 10자 이상 입력해야 합니다');
    } else {
      postInquiry();
    }
  }
  return (
    <InquiryFormCss>
      <HeaderWithImg img={headerImg} detail="문의글" menu="소통창구" />
      <DetailMenu name="소통창구" />
      <table>
        <tr>
          <td>제목*</td>
          <td>
            <input
              type="text"
              className="title"
              placeholder="2자 이상 입력해주세요"
              onChange={(e) => {
                inquiry.current.title = e.target.value;
              }}
            />
          </td>
        </tr>
        <tr>
          <td>작성자*</td>
          <td>
            <input
              type="text"
              className="input"
              placeholder="2자 이상 입력해주세요"
              onChange={(e) => {
                inquiry.current.writer = e.target.value;
              }}
            />
          </td>
        </tr>
        <tr>
          <td>전화번호*</td>
          <td>
            <input
              type="text"
              className="input"
              onChange={(e) => {
                inquiry.current.phone = e.target.value;
              }}
            />
          </td>
        </tr>
        <tr>
          <td>이메일*</td>
          <td>
            <input
              type="text"
              className="email"
              onChange={(e) => {
                emailInput1.current = e.target.value;
                inquiry.current.email =
                  emailInput1.current + '@' + emailInput2.current;
              }}
            />
            &nbsp;&nbsp; @ &nbsp;
            <input
              type="text"
              className="email"
              onChange={(e) => {
                emailInput2.current = e.target.value;
                inquiry.current.email =
                  emailInput1.current + '@' + emailInput2.current;
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="name">내용</td>
          <td>
            <textarea
              placeholder="1000자이내로 입력해주세요"
              className="content"
              onChange={(e) => {
                inquiry.current.content = e.target.value;
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="name last">첨부파일</td>
          <td className="last fileArea">
            <input
              type="file"
              id="file"
              accept=".hwp,.xls,.doc,.ppt,.pdf,.zip,.jpg,.gif,.png"
              onChange={(e) => {
                if (e.target.files !== null && e.target.files.length > 0) {
                  const size =
                    (fileSize.current + e.target.files[0].size) / 1048576;
                  if (size > 10) {
                    alert('전체 파일 크기가 10MB를 초과합니다');
                    return;
                  }
                  fileSizeList.current.push(e.target.files[0].size);
                  fileSize.current += e.target.files[0].size;
                  const tmp: string[] = [...imgPath];
                  tmp.push(
                    `${e.target.files[0].name} (${Math.round(size * 100) / 100}MB)`,
                  );
                  setImgPath(tmp);
                  multipart.current.push(e.target.files[0]);
                }
              }}
            />
            <label htmlFor="file" className="fileLabel">
              파일 선택
            </label>
            {imgPath.map((e, idx) => {
              return (
                <div key={idx} className="fileInfo">
                  <p className="fileName">{e}</p>
                  <HiOutlineXMark
                    className="icon flexContainer"
                    onClick={() => {
                      const size = fileSizeList.current.splice(idx, 1);
                      fileSize.current -= size[0];
                      imgPath.splice(idx, 1);
                      setImgPath([...imgPath]);
                      multipart.current.splice(idx, 1);
                    }}
                  />
                </div>
              );
            })}
            <p className="fileLimit">
              *hwp,xls,doc,ppt,pdf,zip,jpg,gif,png 파일만 가능합니다. <br />
              용량제한 10MB
            </p>
          </td>
        </tr>
      </table>
      <div className="flexContainer">
        <p
          className="toList flexContainer"
          onClick={() => {
            create();
          }}
        >
          저장
        </p>
        <Link to="/inquiry" className="toList flexContainer">
          목록
        </Link>
      </div>
      <Footer />
    </InquiryFormCss>
  );
}
export default InquiryForm;
