import TableOfPosts from 'components/tableOfPosts';
import headerImg from '../assets/headerImg/inquiry.svg';
import { simplePostType } from 'types/postType';
import { useState } from 'react';
import axios from 'axios';
import { inquiryType } from 'types/inquiryType copy';
import hwp from '../assets/hwp.svg';
import pdf from '../assets/pdf.svg';
import fileImg from '../assets/file.svg';
function InquiryList() {
  const header = { src: headerImg, menu: '소통창구', detail: '문의글' };
  const detail = { name: '문의글' };
  const [postList, setPostList] = useState<simplePostType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [pageNum, setPageNum] = useState<number>(0);
  function loadData(type: string, page: number, searchWord: string) {
    if (type === 'none') {
      getData(`/inquiry/all?`, page).catch((e) => {
        console.log(e);
      });
    } else if (type === 'title') {
      getData(`/inquiry/search/title?title=${searchWord}&`, page).catch((e) => {
        console.log(e);
      });
    }
  }

  async function getData(url: string, page: number) {
    const res = await axios.get(
      `${process.env.REACT_APP_ORIGIN}${url}page=${page}&size=8`,
    );
    setTotal(res.data.data.total);
    setPageNum(res.data.data.page);
    const simplePostList: simplePostType[] = [];
    const simplePost: simplePostType = {
      id: -1,
      title: '공지사항 제목',
      src: '',
      create_at: '2020/04/05',
      view: 1000,
    };
    res.data.data.result.forEach((e: inquiryType) => {
      e.create_at = e.create_at.split('T')[0];
      simplePost.id = e.id;
      simplePost.title = e.title;
      simplePost.is_completed = e.is_completed;
      simplePost.create_at = e.create_at.split('T')[0].replaceAll('-', '/');
      if (e.inquiry_img_list.length > 0) {
        if (e.inquiry_img_list[0].src.includes('hwp')) {
          simplePost.src = hwp;
        } else if (e.inquiry_img_list[0].src.includes('pdf'))
          simplePost.src = pdf;
        else simplePost.src = fileImg;
      } else {
        simplePost.src = '';
      }
      simplePostList.push({ ...simplePost });
    });
    setPostList(simplePostList);
  }

  return (
    <TableOfPosts
      header={header}
      detail={detail}
      postList={postList}
      inquiry={true}
      total={total}
      pageNum={pageNum}
      type={'inquiry'}
      loadData={loadData}
    />
  );
}

export default InquiryList;
