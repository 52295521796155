import headerImg from '../assets/headerImg/notice.svg';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { postType } from 'types/postType';
import { imgType } from 'types/imgType';
import NoticePostDetail from 'components/noticePostDetail';

function NoticeDetail() {
  const header = { src: headerImg, menu: '알림마당', detail: '공지사항' };
  const detail = { name: '공지사항' };
  const [post, setPost] = useState<postType>({
    id: -1,
    title: '공지사항 제목',
    content: ``,
    src: [],
    create_at: ' 2024-06-27T20:18:38.193332',
    view: 1000,
    prev: -1,
    next: -1,
  });
  const [fileList, setFileList] = useState<string[]>([]);
  const { id } = useParams();
  useEffect(() => {
    getData().catch((e) => {
      console.log(e);
    });
  }, [id]);

  async function getData() {
    const res = await axios.get(`${process.env.REACT_APP_ORIGIN}/notice/${id}`);
    const data = res.data.data;
    const img_list: string[] = [];
    const file_iist: string[] = [];
    data.notice_img_list.forEach((e: imgType) => {
      if (/(.*?)\.(jpg|jpeg|png|gif|bmp)$/.test(e.src)) img_list.push(e.src);
      else file_iist.push(e.src);
    });
    console.log(file_iist);
    setFileList([...file_iist]);
    setPost({
      id: data.id,
      title: data.title,
      content: data.content,
      src: img_list,
      create_at: data.create_at,
      view: data.view,
      prev: data.prev,
      next: data.next,
    });
  }

  return (
    <NoticePostDetail
      header={header}
      detail={detail}
      listPageLink="/notice"
      post={post}
      file={fileList}
    />
  );
}
export default NoticeDetail;
