import styled from 'styled-components';
import mainBackground from '../assets/mainBackground.svg';
import Footer from 'components/footer';
import Header from 'components/header';
import youtube from '../assets/main/youtube.svg';
import facebook from '../assets/main/facebook.svg';
import instar from '../assets/main/instar.svg';
import blog from '../assets/main/blog.svg';
import cafe from '../assets/main/cafe.svg';
import store from '../assets/main/store.svg';
import HeaderArea from 'components/headerArea';
import MainNoticeList from 'components/mainNoticeList';
import { useRef, useState } from 'react';
import useInteval from 'hooks/useInteval';
const MainCss = styled.div<{ pos: number; length: number }>`
  .mainImgContainer {
    width: 100vw;
    height: 700px;
    overflow: hidden;
    .mainImgList {
      display: flex;
      width: ${(props) => props.length * 100}vw;
      height: 100%;
      transform: translate(${(props) => -props.pos}vw);
      img {
        width: 100vw;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .mainItems {
    position: relative;
    top: -700px;
    margin-bottom: -700px;
    width: 100vw;
    height: 700px;
    box-sizing: border-box;
    .flex {
      display: flex;
      justify-content: space-between;
      height: 600px;
      padding: 100px 5%;
      @media (max-width: 784px) {
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding: 20px 5%;
      }
      @media (min-width: 1280px) and (max-width: 1920px) {
        padding: 100px calc(50vw - 640px);
      }
      box-sizing: border-box;
      .title {
        width: 100%;
        display: flex;
        align-items: end;
        font-size: 48px;
        font-weight: 700;
        color: #ffffff;
        margin: 0;
        @media (max-width: 784px) {
          text-align: left;
          font-size: 24px;
          align-items: center;
          padding-left: 5%;
          padding-bottom: 15px;
        }
      }
    }
  }
  .lists {
    position: relative;
    display: flex;
    height: max-content;
    box-sizing: border-box;
    background: #093c6299;
    margin-bottom: -100px;
    top: -100px;
    @media (min-width: 1280px) and (max-width: 1920px) {
      padding: 0 calc(50vw - 640px);
    }
    @media (max-width: 784px) {
      top: -200px;
      margin-bottom: -200px;
      display: block;
    }
  }
  .list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    padding: 10px 0;
    height: 100px;
    box-sizing: border-box;
    @media (max-width: 784px) {
      width: 100%;
    }
    .items {
      position: relative;
      width: 13%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-decoration: none;
      @media (max-width: 784px) {
        width: 30%;
      }
    }
    p {
      text-align: center;
      color: #ffffff;
      font-size: 14px;
      margin: 0;
      margin-top: 10px;
      font-weight: 500;
    }
  }
`;

function Main() {
  const initImgList = useRef<string[]>([mainBackground, store]);
  const [pos, setPos] = useState<number>(0);
  const [imgList, setImgList] = useState<string[]>([mainBackground, store]);
  const slider = useRef<HTMLDivElement>(null);
  useInteval(() => {
    setTimeout(() => {
      if (slider.current !== null)
        slider.current.style.transitionDuration = '0ms';
      if (pos / 100 === imgList.length - 2)
        setImgList([...imgList, ...initImgList.current]);
    }, 800);
    if (slider.current !== null)
      slider.current.style.transitionDuration = '800ms';
    setPos(pos + 100);
  }, 10000);
  return (
    <MainCss pos={pos} length={imgList.length}>
      <Header />
      <HeaderArea />
      <div className="mainImgContainer">
        <div className="mainImgList" ref={slider}>
          {imgList.map((e, idx) => {
            return <img src={e} key={idx} />;
          })}
        </div>
      </div>
      <div className="mainItems">
        <div className="flex">
          <p className="title">주식회사 동행하는 사람들</p>
          <MainNoticeList />
        </div>
      </div>
      <div className="lists">
        <div className="list">
          <a
            className="items"
            href="https://youtube.com/@hokisim_queen?si=rkscJjIBxrDZHB7g"
          >
            <img src={youtube} />
            <p>youtube</p>
          </a>
          <a className="items" href="">
            <img src={facebook} />
            <p>facebook</p>
          </a>
          <a
            className="items"
            href="https://www.instagram.com/nooni_donghangsa?igsh=MTlqMDIyOTJ0eGxsdA%3D%3D&utm_source=qr"
          >
            <img src={instar} />
            <p>instagram</p>
          </a>
        </div>
        <div className="list">
          <a className="items" href="">
            <img src={blog} />
            <p>공식블로그</p>
          </a>
          <a className="items" href="">
            <img src={cafe} />
            <p>공식카페</p>
          </a>
          <a className="items" href="https://smartstore.naver.com/donghangsa">
            <img src={store} />
            <p>스마트스토어</p>
          </a>
        </div>
      </div>
      <Footer />
    </MainCss>
  );
}

export default Main;
