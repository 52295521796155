import Header from './header';
import styled from 'styled-components';
import HeaderArea from './headerArea';
type headerProps = {
  img: string;
  menu?: string;
  detail: string;
  height?: string;
};

const HeaderWithImgCss = styled.div`
  .menuImg {
    width: 100vw;
    height: 250px;
    object-fit: cover;
    @media (max-width: 784px) {
      height: 100px;
    }
  }
  .menuTitle {
    position: relative;
    width: 100vw;
    height: 250px;
    top: -250px;
    margin-bottom: -250px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-position: center;
    background-size: cover;
    box-sizing: border-box;
    padding: 0 10%;
    @media (min-width: 1280px) and (max-width: 1920px) {
      padding: 0 calc(50vw - 640px);
    }
    @media (max-width: 784px) {
      height: 100px;
      padding: 0 5%;
      top: -100px;
      margin-bottom: -100px;
    }
  }
  .menuName {
    font-size: 20px;
    font-weight: 400px;
    margin: 0;
    @media (max-width: 784px) {
      display: none;
    }
  }
  .detailName {
    font-size: 48px;
    font-weight: 700;
    margin: 0;
    @media (max-width: 784px) {
      font-size: 24px;
    }
  }
`;

function HeaderWithImg({ img, menu = '', detail }: headerProps) {
  return (
    <HeaderWithImgCss>
      <Header />
      <HeaderArea />
      <img src={img} className="menuImg" />
      <div className="menuTitle">
        <p className="menuName">{menu}</p>
        <p className="detailName">{detail}</p>
      </div>
    </HeaderWithImgCss>
  );
}

export default HeaderWithImg;
