import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from 'pages/main';
import Intro from 'pages/intro';
import Gobility from 'pages/gobility';
import Education from 'pages/education';
import Exhibition from 'pages/exhibition';
import ArticleList from 'pages/articleList';
import NoticeList from 'pages/noticeList';
import InquiryList from 'pages/inquiryList';
import NewsLetterList from 'pages/newsletterList';
import EventList from 'pages/eventList';
import NoticeDetail from 'pages/noticeDetail';
import InquiryForm from 'pages/inquiryForm';
import ArticleDetail from 'pages/articleDetail';
import NewsletterDetail from 'pages/newsletterDetail';
import EventDetail from 'pages/eventDetail';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/intro" element={<Intro />} />
        <Route path="/business/go-ablity" element={<Gobility />} />
        <Route path="/business/education" element={<Education />} />
        <Route path="/business/exhibition" element={<Exhibition />} />
        <Route path="/business/counseling" element={<></>} />
        <Route path="/bussiness/equipment" element={<></>} />
        <Route path="/notice" element={<NoticeList />} />
        <Route path="/notice/detail/:id" element={<NoticeDetail />} />
        <Route path="/article" element={<ArticleList />} />
        <Route path="/article/detail/:id" element={<ArticleDetail />} />
        <Route path="/newsletter" element={<NewsLetterList />} />
        <Route path="/newsletter/detail/:id" element={<NewsletterDetail />} />
        <Route path="/event" element={<EventList />} />
        <Route path="/event/detail/:id" element={<EventDetail />} />
        <Route path="/inquiry" element={<InquiryList />} />
        <Route path="/inquiry/form" element={<InquiryForm />} />
      </Routes>
    </Router>
  );
}

export default App;
