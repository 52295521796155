import { useState } from 'react';
import styled from 'styled-components';
import DetailMenu from 'components/detailMenu';
import Footer from 'components/footer';
import HeaderWithImg from 'components/headerWithImg';
import ServiceDescription from 'components/serviceDescription';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
const BussinessCss = styled.div<{ pos: number; length: number }>`
  .imgContainer {
    margin: 50px 10% 100px 10%;
    height: 27vw;
    width: 80vw;
    overflow: hidden;
    @media (min-width: 1280px) and (max-width: 1920px) {
      width: 1280px;
      margin: 50px calc(50vw - 640px) 100px calc(50vw - 640px);
    }
    @media (max-width: 784px) {
      width: 100vw;
      margin: 0 0 40px 0;
      height: 60vw;
    }
    .imgWrap {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .imgSlider {
      width: ${(props) => props.length * 100}%;
      height: 100%;
      transform: translate(${(props) => -props.pos / props.length}%);
      transition: 0.5s;
      img {
        width: ${(props) => 100 / props.length}%;
        height: 100%;
        object-fit: cover;
      }
    }
    .imgBtnArea {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      position: relative;
      top: -100%;
    }
    .imgBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 90px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 100%;
      cursor: pointer;
      @media (max-width: 784px) {
        margin: 0 20px;
      }
      .arrow {
        padding: 20px;
        font-size: 24px;
        @media (max-width: 784px) {
          padding: 10px;
          font-size: 18px;
        }
      }
    }
  }
  .prev {
    ${(props) => props.pos === 0 && 'visibility:hidden; cursor: auto'};
  }
  .next {
    ${(props) => {
      return (
        props.pos === (props.length - 1) * 100 &&
        ' visibility:hidden; cursor:none'
      );
    }};
  }
  .des {
    padding: 0 10% 500px 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (min-width: 1280px) and (max-width: 1920px) {
      width: 1280px;
      padding: 0px calc(50vw - 640px) 300px calc(50vw - 640px);
    }
    @media (max-width: 784px) {
      padding: 0 5% 100px 5%;
      justify-content: center;
    }
  }
`;
type props = {
  header: { src: string; detail: string };
  detail: { name: string; description: string };
  imgList: { src: string; alt: string }[];
  service: { src: string; text: string }[];
};
function Bussiness({ imgList, service, detail, header }: props) {
  const [pos, setPos] = useState<number>(0);
  function nextImg() {
    if (pos < 100 * (imgList.length - 1)) setPos(pos + 100);
  }
  function prevImg() {
    if (pos > 0) setPos(pos - 100);
  }
  return (
    <BussinessCss pos={pos} length={imgList.length}>
      <HeaderWithImg img={header.src} menu="사업 소개" detail={header.detail} />
      <DetailMenu name={detail.name} description={detail.description} />
      <div className="imgContainer">
        <div className="imgWrap">
          <div className="imgSlider">
            {imgList.map((e, idx) => {
              return <img src={e.src} key={idx} alt={e.alt} />;
            })}
          </div>
        </div>
        <div className="imgBtnArea">
          <div
            className="imgBtn prev"
            onClick={() => {
              prevImg();
            }}
            style={{ visibility: 'visible' }}
          >
            <FaChevronLeft className="arrow" />
          </div>
          <div
            className="imgBtn next"
            onClick={() => {
              nextImg();
            }}
          >
            <FaChevronRight className="arrow" />
          </div>
        </div>
      </div>
      <div className="des">
        {service.map((e, idx) => {
          return <ServiceDescription img={e.src} text={e.text} key={idx} />;
        })}
      </div>
      <Footer />
    </BussinessCss>
  );
}
export default Bussiness;
