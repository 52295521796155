import styled from 'styled-components';
import logo from '../assets/logo.svg';
import { Link } from 'react-router-dom';
const FooterCss = styled.div`
  width: 80vw;
  color: #060606;
  padding: 50px 10vw;
  @media (min-width: 1280px) and (max-width: 1920px) {
    padding: 50px calc(50vw - 640px);
  }
  .footerLogo {
    width: 230px;
    height: 40px;
  }
  .policyList {
    width: 100%;
    display: flex;
    .policy {
      margin-right: 20px;
      font-size: 18px;
      font-weight: 700;
      text-decoration: none;
      color: #060606;
    }
    .policy:hover {
      color: #1c7393;
    }
  }

  .text {
    margin: 2rem;
  }

  .info {
    display: flex;
    width: 100%;
    font-size: 15px;
    box-sizing: border-box;
    margin-top: 30px;
    min-height: 150px;
    p {
      margin: 4px 0;
    }
    .left {
      display: flex;
      align-items: center;
    }

    .right {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: center;
      margin-left: 50px;
    }
  }

  @media (max-width: 784px) {
    width: 90vw;
    padding: 50px 5vw;
    .info {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 12px;
      color: #76787b;
      .left {
        margin: 40px 0;
      }
      .right {
        width: 100%;
        align-items: center;
        margin: 0px;
      }
      p {
        text-align: center;
      }
    }
    .text {
      margin: 1rem;
    }

    .policyList {
      width: 100%;
      .policy {
        margin: 0;
        font-size: 14px;
        font-weight: 700;
        color: #76787b;
        flex-grow: 1;
        text-align: center;
      }
    }
  }
`;
function Footer() {
  const footerMenu: { [key: string]: string } = {
    FAQ: '/',
    개인정보처리방침: '/',
    이용약관: '/',
  };
  const key = Object.keys(footerMenu);
  return (
    <FooterCss>
      <div className="policyList">
        {key.map((e, idx) => {
          return (
            <Link to={footerMenu[e]} key={idx} className="policy">
              {e}
            </Link>
          );
        })}
      </div>
      <div className="info">
        <div className="left">
          <img src={logo} alt="logo" className="footerLogo" />
        </div>
        <div className="right">
          <p>
            대표자명: 박하은
            <span className="text">사업자 등록번호: 499-86-02162</span>
          </p>
          <p>
            (31156) 충청남도 천안시 서북구 불당23로 70정우프라자 701 ~ 702,
            k-40호
          </p>
          <p>
            TEL : 070-4506-9977(토,일 휴)
            <span className="text">FAX : 0504-082-2613</span>
          </p>
          <p>Copyright © 2023 DongHangSa. All Rights Reserved.</p>
        </div>
      </div>
    </FooterCss>
  );
}

export default Footer;
