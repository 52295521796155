import Bussiness from '../components/bussiness';
import exhibitionHeaderImg from '../assets/headerImg/exhibition.svg';
import desc1 from '../assets/bussiness/exhibition_desc_1.svg';
import desc2 from '../assets/bussiness/exhibition_desc_2.svg';
import desc3 from '../assets/bussiness/exhibition_desc_3.svg';
function Exhibition() {
  const header = { src: exhibitionHeaderImg, detail: '전시회' };
  const detail = {
    name: '시청각 통합 장애인식개선 체험 전시회',
    description: `약시에 초점을 맞춘 시각장애 체험뿐 아니라 청각, 시청각 중복 장애를 다양한 방식으로 체험함으로 보이지 않고, 
    들리지 않는 상황에서 시각, 청각 이외 다양한 감각들에 집중하는 시간을 지원합니다.`,
  };
  const imgList: { src: string; alt: string }[] = [];
  const service = [
    {
      src: desc1,
      text: '약시 시각장애 체험',
    },
    {
      src: desc2,
      text: '청각장애 체험',
    },
    {
      src: desc3,
      text: '시청각 장애 체험',
    },
  ];
  return (
    <Bussiness
      header={header}
      detail={detail}
      imgList={imgList}
      service={service}
    />
  );
}

export default Exhibition;
