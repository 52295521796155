import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
const MainNoticeListCss = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 40px 20px;
  min-width: 350px;
  box-sizing: border-box;
  height: 430px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  @media (max-width: 784px) {
    width: 100%;
    max-width: 300px;
    min-width: 300px;
    height: 410px;
    padding: 20px 20px;
  }
  .link {
    text-decoration: none;
    display: flex;
  }
  .gray {
    color: #c6c8cb;
  }

  .noticeHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 30px;
    .text,
    .detail {
      display: flex;
      align-items: center;
      margin: 0;
    }
    .text {
      font-weight: 700;
      font-size: 22px;
    }
    .detail {
      font-weight: 400;
      font-size: 12px;
    }
  }

  .noticeItem {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: black;
    margin: 5px 0;
    .noticeName,
    .date {
      margin: 10px 0;
    }
    .noticeName {
      width: 65%;
      color: #76787b;
      white-space: break-spaces;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 5%;
    }

    .noticeName::before {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      margin-top: 7px;
      margin-left: -12px;
      background: #c6c8cb;
      border-radius: 100%;
    }
    .date {
      text-align: right;
      width: 30%;
    }
  }
`;
type simpleNoticeType = {
  id: number;
  title: string;
  create_at: string;
};
function MainNoticeList() {
  const [noticeList, setNoticeList] = useState<simpleNoticeType[]>([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ORIGIN}/notice/all?page=0&size=8`)
      .then((res) => {
        const tmp: simpleNoticeType[] = [];
        const notice: simpleNoticeType = {
          id: 1,
          title: '',
          create_at: '2024.06.03',
        };
        for (const e of res.data.data.result) {
          notice.id = e.id;
          notice.title = e.title;
          notice.create_at = e.create_at.split('T')[0].replaceAll('-', '.');
          tmp.push({ ...notice });
        }
        setNoticeList(tmp);
      });
  }, []);
  return (
    <MainNoticeListCss>
      <div className="noticeHeader">
        <p className="text">공지사항</p>
        <Link to="/notice" className="detail link gray">
          자세히
        </Link>
      </div>
      <div className="noticeList">
        {noticeList.map((e, idx) => {
          return (
            <Link
              to={`/notice/detail/${e.id}`}
              className="noticeItem link"
              key={idx}
            >
              <p className="noticeName">{e.title}1</p>
              <p className="gray date">{e.create_at}</p>
            </Link>
          );
        })}
      </div>
    </MainNoticeListCss>
  );
}
export default MainNoticeList;
