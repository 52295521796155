import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg';
import useMenuStore from 'store/menuStroe';
import { IoIosMenu } from 'react-icons/io';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa6';
const HeaderCss = styled.div`
  position: fixed;
  background: #ffffff;
  z-index: 100;
  .header {
    display: flex;
    width: 100vw;
    height: 100px;
    border-bottom: 1px solid #0e0e0e22;
    box-sizing: border-box;
    padding: 0 5%;
    @media (min-width: 1280px) and (max-width: 1920px) {
      padding: 0 calc(50vw - 640px);
    }
  }

  .flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    width: 20%;
  }
  .menuList {
    display: flex;
    width: 70%;
    height: 100%;
    box-sizing: border-box;
  }
  .menuIcon {
    width: 20%;
    display: none;
    align-items: center;
    justify-content: center;
  }

  .menu {
    margin: 0;
    flex-direction: column;
    cursor: pointer;
    color: #060606;
    font-size: 20px;
    font-weight: 700;
    flex-grow: 1;
    height: 100%;
    box-sizing: border-box;
  }

  .line {
    display: block;
    position: relative;
    width: 40%;
    height: 3px;
    background: #1c7393;
    top: 5px;
  }
  .detailMenuContainer {
    display: none;
    width: 100vw;
    padding: 0 5%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.22);
    background: #ffffff;
    box-sizing: border-box;
    @media (min-width: 1280px) and (max-width: 1920px) {
      padding: 0 calc(50vw - 640px);
    }
  }
  .detailMenuArea {
    display: none;
    display: flex;
    width: 70%;
  }
  .header:has(.menu:hover) + .detailMenuContainer {
    display: flex;
  }
  .detailMenuContainer:hover {
    display: flex;
  }

  .detailMenuList {
    width: 20%;
    border-right: 1px solid #0e0e0e22;
    text-align: center;
    padding-bottom: 20px;
  }
  .detailMenuList:nth-child(1) {
    border-left: 1px solid #0e0e0e22;
  }
  .detailMenu,
  .mobileMenu {
    width: 100%;
    display: block;
    text-decoration: none;
    color: #060606;
    font-weight: 400;
  }
  .detailMenu {
    font-size: 20px;
    color: #060606;
    padding: 20px 0;
    text-align: center;
  }
  .mobileMenu {
    padding: 20px 40px;
    background: #eef0f3;
    border-bottom: 1px solid #0e0e0e22;
    &:last-child {
      border-bottom: 0;
    }
  }
  .detailMenu:hover,
  .mobileMenu:hover {
    color: #1c7393;
    font-weight: 700;
  }
  .mobilMenuArea {
    display: none;
    border-top: 1px solid #0e0e0e22;
    border-bottom: 1px solid #0e0e0e22;
    height: calc(100vh - 60px);
    width: 100vw;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .mobileMenuTitle {
    display: flex;
    padding: 20px 40px;
    margin: 0;
    font-weight: 700;
    border-bottom: 1px solid #c6c8cb;
    justify-content: space-between;
    font-size: 18px;
  }
  .blur {
    color: #c6c8cb;
    cursor: pointer;
  }

  @media (max-width: 784px) {
    .header {
      height: 60px;
    }
    .menuList {
      margin: 0;
    }
    .logo {
      justify-content: start;
      padding-left: 15px;
    }

    .logoIcon {
      width: 100px;
      height: 50px;
    }

    .detailMenuArea,
    .menu {
      display: none;
    }
    .menuIcon {
      display: flex;
      font-size: 30px;
    }

    .mobilMenuArea {
      display: block;
    }
  }
`;

function Header() {
  const menu: { [key: string]: { [key: string]: string } } = {
    '기업 소개': { 기업소개: '/intro' },
    '사업 안내': {
      고빌리티: '/business/go-ablity',
      '체험/교육': '/business/education',
      전시회: '/business/exhibition',
      '심리검사&상담': '/business/counseling',
      복지용구: '/bussiness/equipment',
    },
    알림마당: { 공지사항: '/notice', 언론보도: '/article' },
    홍보마당: { 뉴스레터: '/newsletter', 행사앨범: '/event' },
    소통창구: { 문의글: '/inquiry' },
  };
  type props = {
    data: {
      [key: string]: string;
    };
    current: number;
    title?: string;
  };
  const blur: { [key: string]: boolean } = {
    '심리검사&상담': true,
    복지용구: true,
  };
  const key: string[] = Object.keys(menu);
  const [showMobilMenu, setShowMobilMenu] = useState<boolean>(false);
  const { current, setCurrent } = useMenuStore();
  function DetailMenuList({ data, current }: props) {
    return (
      <div className="detailMenuList">
        {Object.keys(data).map((e, idx) => {
          return blur[e] ? (
            <div key={idx} className="detailMenu blur">
              {e}
            </div>
          ) : (
            <Link
              key={idx}
              to={data[e]}
              className="detailMenu"
              onClick={() => {
                setCurrent(current);
              }}
            >
              {e}
            </Link>
          );
        })}
      </div>
    );
  }
  function MobileMenuList({ data, current, title }: props) {
    const [state, setState] = useState<boolean>(false);
    return (
      <div className="mobileMenuList">
        <p
          className="mobileMenuTitle"
          onClick={() => {
            setState(!state);
          }}
        >
          {title}
          {state ? <FaAngleUp /> : <FaAngleDown />}
        </p>
        <div
          style={{
            transition: '0.3s ease-in-out',
            maxHeight: state ? `${70 * Object.keys(data).length}px` : '0px',
            overflow: 'hidden',
          }}
        >
          {Object.keys(data).map((e, idx) => {
            return blur[e] ? (
              <div key={idx} className="mobileMenu blur">
                {e}
              </div>
            ) : (
              <Link
                key={idx}
                to={data[e]}
                className="mobileMenu"
                onClick={() => {
                  setCurrent(current);
                }}
              >
                {e}
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <HeaderCss>
      <div className="header">
        <Link
          to="/"
          className="logo flexCenter"
          onClick={() => {
            setCurrent(-1);
          }}
        >
          <img src={logo} className="logoIcon" alt="logo" />
        </Link>
        <div className="menuList">
          {key.map((e, idx) => {
            return current === idx ? (
              <p
                className="menu flexCenter"
                key={idx + ' ' + current}
                style={{ color: '#1c7393', paddingTop: '3px' }}
              >
                {e}
                <span className="line" />
              </p>
            ) : (
              <p className="menu flexCenter" key={idx}>
                {e}
              </p>
            );
          })}
        </div>
        <div className="menuIcon">
          <IoIosMenu
            onClick={() => {
              setShowMobilMenu(!showMobilMenu);
            }}
          />
        </div>
      </div>
      <div className="detailMenuContainer">
        <div className="logo"></div>
        <div className="detailMenuArea">
          {key.map((e, idx) => {
            return <DetailMenuList key={idx} data={menu[e]} current={idx} />;
          })}
        </div>
      </div>
      {showMobilMenu && (
        <div className="mobilMenuArea">
          {key.map((e, idx) => {
            return (
              <MobileMenuList
                key={idx}
                data={menu[e]}
                current={idx}
                title={e}
              />
            );
          })}
        </div>
      )}
    </HeaderCss>
  );
}

export default Header;
