import ListOfPost from 'components/listOfPosts';
import headerImg from '../assets/headerImg/eventAlbum.svg';
import { simplePostType } from 'types/postType';
import { useState } from 'react';
import axios from 'axios';
import { eventType } from 'types/eventType';
function EventList() {
  const header = { src: headerImg, menu: '홍보마당', detail: '행사앨범' };
  const detail = { name: '행사앨범' };
  const [postList, setPostList] = useState<simplePostType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [pageNum, setPageNum] = useState<number>(0);
  function loadData(type: string, page: number, searchWord: string) {
    if (type === 'none') {
      getData(`/event/all?`, page).catch((e) => {
        console.log(e);
      });
    } else if (type === 'all') {
      getData(
        `/event/search/all?title=${searchWord}&content=${searchWord}&`,
        page,
      ).catch((e) => {
        console.log(e);
      });
    } else if (type === 'title') {
      getData(`/event/search/title?title=${searchWord}&`, page).catch((e) => {
        console.log(e);
      });
    } else if (type === 'contnet') {
      getData(`/event/search/content?content=${searchWord}&`, page).catch(
        (e) => {
          console.log(e);
        },
      );
    }
    return;
  }
  async function getData(url: string, page: number) {
    const res = await axios.get(
      `${process.env.REACT_APP_ORIGIN}${url}page=${page}&size=8`,
    );
    setTotal(res.data.data.total);
    setPageNum(res.data.data.page);
    const simplePostList: simplePostType[] = [];
    const simplePost: simplePostType = {
      id: 0,
      title: '',
      view: 0,
      src: '',
      create_at: '',
    };
    res.data.data.result.forEach((e: eventType) => {
      simplePost.id = e.id;
      simplePost.title = e.title;
      simplePost.view = e.view;
      simplePost.src =
        e.event_img_list.length > 0 ? e.event_img_list[0].src : '';
      simplePost.create_at = e.create_at.split('T')[0].replaceAll('-', '/');

      simplePostList.push({ ...simplePost });
    });
    setPostList(simplePostList);
  }
  return (
    <ListOfPost
      header={header}
      detail={detail}
      postList={postList}
      total={total}
      pageNum={pageNum}
      type={'event'}
      loadData={loadData}
    />
  );
}

export default EventList;
