import { create } from 'zustand';
type menuStroeType = {
  current: number;
  setCurrent: (menu: number) => void;
};
const useMenuStore = create<menuStroeType>((set) => ({
  current: -1,
  setCurrent: (menu) => set({ current: menu }),
}));

export default useMenuStore;
