import Bussiness from '../components/bussiness';
import gobilityHeaderImg from '../assets/headerImg/gobility.svg';
import desc1 from '../assets/bussiness/gobility_desc_1.svg';
import desc2 from '../assets/bussiness/gobility_desc_2.svg';
import desc3 from '../assets/bussiness/gobility_desc_3.svg';
import desc4 from '../assets/bussiness/gobility_desc_4.svg';
function Gobility() {
  const header = { src: gobilityHeaderImg, detail: '고빌리티(Go-Ability)' };
  const detail = {
    name: '고빌리티(Go-Ability)',
    description: `병원동행, 식품구매, 책 읽기 등 일상생활에서 도움이 필요한 장애인 및 
    어르신 대상으로 일시적인 활동 보조 뿐 아니라 
    응급상황 발생 시 주변 지인, 시민, 활동지원사를 연결해 긴급 출동을 지원합니다.`,
  };
  const imgList: { src: string; alt: string }[] = [];
  const service = [
    {
      src: desc1,
      text: '병원동행, 키오스크 사용, 식사보조, 튜터링 등 일상생활 보조 지원',
    },
    {
      src: desc2,
      text: '갑자기 쓰러짐, 움직임과 호흡 불안정 등 응급상황 보조 지원',
    },
    {
      src: desc3,
      text: `장애인 활동지원사, 요양보호사, 사회복지사 등 
      자격증 소유자를 통한 긴급 및 예약 지원`,
    },
    {
      src: desc4,
      text: '도보 20분 이내 긴급 출동이 가능한 주변 지인, 시민, 복지관 등을 통한 긴급지원',
    },
  ];
  return (
    <Bussiness
      header={header}
      detail={detail}
      imgList={imgList}
      service={service}
    />
  );
}

export default Gobility;
