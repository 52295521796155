import headerImg from '../assets/headerImg/notice.svg';
import { simplePostType } from 'types/postType';
import { useState } from 'react';
import { noticeType } from 'types/noticeType';
import axios from 'axios';
import hwp from '../assets/hwp.svg';
import pdf from '../assets/pdf.svg';
import fileImg from '../assets/file.svg';
import TableOfPosts from 'components/tableOfPosts';
function Noticeist() {
  const header = { src: headerImg, menu: '알림마당', detail: '공지사항' };
  const detail = { name: '공지사항' };
  const [postList, setPostList] = useState<simplePostType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [pageNum, setPageNum] = useState<number>(0);

  function loadData(type: string, page: number, searchWord: string) {
    if (type === 'none') {
      getData(`/notice/all?`, page).catch((e) => {
        console.log(e);
      });
    } else if (type === 'all') {
      getData(
        `/notice/search/all?title=${searchWord}&content=${searchWord}&`,
        page,
      ).catch((e) => {
        console.log(e);
      });
    } else if (type === 'title') {
      console.log();
      getData(`/notice/search/title?title=${searchWord}&`, page).catch((e) => {
        console.log(e);
      });
    } else if (type === 'contnet') {
      getData(`/notice/search/content?content=${searchWord}&`, page).catch(
        (e) => {
          console.log(e);
        },
      );
    }
    return;
  }

  async function getData(url: string, page: number) {
    const res = await axios.get(
      `${process.env.REACT_APP_ORIGIN}${url}page=${page}&size=8`,
    );
    setTotal(res.data.data.total);
    setPageNum(res.data.data.page);
    const simplePostList: simplePostType[] = [];
    const simplePost: simplePostType = {
      id: -1,
      title: '공지사항 제목',
      src: '',
      create_at: '2020/04/05',
      view: 1000,
    };
    res.data.data.result.forEach((e: noticeType) => {
      simplePost.id = e.id;
      simplePost.title = e.title;
      simplePost.view = e.view;
      if (e.notice_img_list.length > 0) {
        if (e.notice_img_list[0].src.includes('hwp')) simplePost.src = hwp;
        else if (e.notice_img_list[0].src.includes('pdf')) simplePost.src = pdf;
        else simplePost.src = fileImg;
      } else {
        simplePost.src = '';
      }
      simplePost.create_at = e.create_at.split('T')[0].replaceAll('-', '/');
      simplePostList.push({ ...simplePost });
    });
    setPostList(simplePostList);
  }
  return (
    <TableOfPosts
      header={header}
      detail={detail}
      postList={postList}
      total={total}
      pageNum={pageNum}
      type={'notice'}
      loadData={loadData}
    />
  );
}

export default Noticeist;
