import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { simplePostType } from 'types/postType';
const ImgPostCss = styled.div`
  width: 23%;
  margin-bottom: 40px;
  @media (max-width: 784px) {
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #c6c8cb;
  }
  .link {
    text-decoration: none;
    color: #000000;
  }

  img {
    width: 100%;
    height: 230px;
    background: #c6c8cb;
    object-fit: cover;
  }
  .postTitle {
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0;
  }
  .container {
    display: flex;
    color: #1b1d1f;
    justify-content: space-between;
    span {
      color: #76787b;
    }
    .text {
      margin: 0;
    }
  }
`;
type props = {
  type: string;
};
function ImgPost({
  id,
  title,
  src,
  create_at,
  view,
  type,
}: simplePostType & props) {
  const viewString = view.toLocaleString();
  return (
    <ImgPostCss>
      <Link to={`/${type}/detail/${id}`} className="link">
        <img src={src} />
        <p className="postTitle">{title}</p>
        <div className="container">
          <p className="text">
            작성일&nbsp;&nbsp;<span>{create_at}</span>
          </p>
          <p className="text">
            조회수&nbsp;&nbsp;<span>{viewString}</span>
          </p>
        </div>
      </Link>
    </ImgPostCss>
  );
}

export default ImgPost;
